import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home/home';
import SmartPassLogin from './components/LogIn/SmartPassLogin';
import Login from './components/LogIn/Login';
import Accreg from './components/AccountRegistration/Accreg';
import CreateData from './components/CreateData/CreateData';
import ViewDetail from './components/ViewDetail/viewDetail';
import ViewCreatedData from './components/View/view'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Login />} />
        <Route path='smartpass' element={<SmartPassLogin />} />
        <Route path='home.adm' element={<Home />} />
        <Route path='accreg.adm' element={<Accreg />} />
        <Route path='createdata.adm' element={<CreateData />} />
        <Route path='detail' element={<ViewDetail />} />
        <Route path='view.adm' element={<ViewCreatedData />} />
        {/* <Route path='myaccount.adm' element={<MyAcc />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();