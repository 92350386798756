import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Col, Row, Toast, Modal, ToastContainer } from 'react-bootstrap';
import { auth, logInWithEmailAndPassword, SECRET_KEY, firebaseConfig } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import Turnstile, { useTurnstile } from "react-turnstile";
import CryptoJS from 'crypto-js';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { isMobile } from 'react-device-detect';
import "./Login.css";
import { toast } from "react-toastify";
import { TiInfoLarge } from "react-icons/ti";
import { FaChrome } from "react-icons/fa";
import { HiKey, HiOutlineKey } from "react-icons/hi2";
import "./Login.css";

// const currentSystem = window.location.hostname;

function TurnstileWidget({ setIsTurnstileCompleted }) {
    const turnstile = useTurnstile();
    return (
        <Turnstile
            sitekey="0x4AAAAAAAOvOY6OgNWdFWBQ"
            onVerify={(token) => {
                setIsTurnstileCompleted(true);
            }}
        />
    );
}

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);

    let boolLocal = false;
    if (window.location.hostname === "localhost") {
        boolLocal = true;
    }
    const [isTurnstileCompleted, setIsTurnstileCompleted] = useState(boolLocal);

    // For Passwordless Sign In
    const [emailInput, setEmailInput] = useState("");
    const [show, setShow] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const toggleShowToast = () => setShowToast(!showToast);

    const [passlessLoginSuccess, setPasslessLoginSuccess] = useState();
    const handlePasswordlessSignIn = async (email, event) => {
        event.preventDefault();
        const actionCodeSettings = {
            url: window.location.href, // This URL must be a URL of your app, and must be whitelisted in the Firebase Console.
            handleCodeInApp: true,
        };
        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
                setPasslessLoginSuccess(true);
                setShowToast(true);
            });
            let ciphertext = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
            localStorage.setItem('ue', JSON.stringify(ciphertext));
            window.localStorage.setItem('emailForSignIn', email);
        } catch (err) {
            setPasslessLoginSuccess(false);
            setShowToast(true);
            console.error(err);
        }
    };

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            const email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                setPasslessLoginSuccess(false);
                setShowToast(true);
                // email = window.prompt('Please provide your email for confirmation');
            }
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
        console.log('Loading:', loading);
        console.log('User:', user);
        if (loading) {
            return;
        }
        if (user) navigate("/home.adm");
    }, [user, loading]);

    // if (isMobile) {
    //     return (
    //         <Container className='logincontainer align-items-center d-flex navless-container'>
    //             <div className="m-auto formwidth mx-3">
    //                 <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />
    //                 <h4>Mobile Currently Not Supported</h4>
    //                 <p>You cannot access with mobile.</p>
    //             </div>
    //         </Container>
    //     );
    // }

    return (
        <>
            <Row className="loginrow">
                <Col sm={5}>
                    <Container
                        // className={currentSystem == "admin-info-go.nugmyanmar.org" ? 'd-flex imgcontainer imgbg align-items-center' : 'd-flex imgcontainer eduimgbg align-items-center'
                        className={'d-flex imgcontainer imgbg align-items-center'
                        }>

                        <div className="m-auto">

                            {/* <h1 className="h5 text-center text-light">
                                <span className="d-lg-inline">
                                    Go.NUG
                                </span>
                            </h1> */}

                            {/* <img src={ currentSystem == 'admin-info-go.nugmyanmar.org' ? 'https://nationalunitygovernment.b-cdn.net/assets/state/go.nug-loginbg.svg' : 'https://nationalunitygovernment.b-cdn.net/assets/state/edu.nug/edu.nug-loginbg-test.svg'} width={500}></img> */}
                            <img src={'https://nationalunitygovernment.b-cdn.net/assets/state/go.nug-loginbg.svg'} width={500}></img>

                        </div>

                    </Container>
                </Col>
                <Col sm={7}>
                    <Container className='d-flex logincontainer align-items-center'>

                        <div className="m-auto formwidth">
                            <div className="py-3">
                                <div className="d-flex">
                                    <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />
                                    <div className="statusbadge">
                                        <iframe src="https://status.go.nugmyanmar.org/badge?theme=white" width="250" height="30" frameBorder="0" scrolling="no"></iframe>
                                    </div>
                                </div>
                                <label className="text-muted">Only available for use by public officers with an NUG official email addresses.</label>
                            </div>

                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        // placeholder={currentSystem == "admin-info-go.nugmyanmar.org" ? "name@agency.nugmyanmar.org" : "name@edu.nugmyanmar.org"}
                                        placeholder={"name@agency.nugmyanmar.org"}
                                        value={emailInput}
                                        disabled={!isTurnstileCompleted}
                                        onChange={e => setEmailInput(e.target.value)} />
                                    <Form.Text className="text-muted">
                                        We'll send you a sign in link to this email.
                                    </Form.Text>
                                </Form.Group>
                            </Form>

                            <div className="my-3 d-flex align-items-center">
                                <Button
                                    // variant={currentSystem == "admin-info-go.nugmyanmar.org" ? "danger" : null}
                                    // className={currentSystem == "admin-info-go.nugmyanmar.org" ? null : "edu-btn"}
                                    variant="danger"
                                    style={{ width: '30%' }}
                                    disabled={!isTurnstileCompleted}
                                    onClick={(event) => handlePasswordlessSignIn(emailInput, event)}>
                                    Sign in
                                </Button>
                            </div>
                            <div className="my-4">
                                <TurnstileWidget setIsTurnstileCompleted={setIsTurnstileCompleted} />
                            </div>
                            <div className="recommended-browser d-flex bg-s0">
                                <div className="d-flex justify-content-center bg-s70 info-pic">
                                    <TiInfoLarge color="grey" style={{ margin: '10px 0 0 10px' }} />
                                </div>
                                <div className="sm text-muted" style={{ padding: '8px 12px 8px 10px', width: '327px' }}>
                                    For the best experience, please use with the latest version of&nbsp;
                                    <span>
                                        <FaChrome />
                                    </span>
                                    &nbsp;Chrome browser.
                                </div>
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>

            <ToastContainer
                className="p-3 position-absolute top-0 end-0"
                position={'top-end'}
                style={{ zIndex: 1 }}
            >
                <Toast show={showToast} onClose={toggleShowToast} delay={3000} autohide>
                    <Toast.Header>
                        <img
                            src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                            className="rounded me-2"
                            width={20}
                            alt="Go.NUG Logo"
                        />
                        <strong className="me-auto">Go.NUG</strong>
                    </Toast.Header>
                    <Toast.Body>{passlessLoginSuccess ? "Passwordless email is Successfully Sent" : "An error occurred: may be login with non signup email!"}</Toast.Body>          </Toast>
            </ToastContainer>
        </>

    );
}
export default Login;