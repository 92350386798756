import React, { Component } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap';
import "./Topbar.css";
import { constants } from '../../global/const';

export default class TopBar extends Component {
    render() {
        return (
            <Container className='nugstart' fluid>
                <Container>
                    <div>
                        <img src={constants.stateemblem} alt="Myanmar State Emblem" width="30" height="25"></img>
                        <a className='nug hypernug' href="https://nugmyanmar.org" target="_blank" rel="noopener"><span>An Official Website of the National Unity Government</span></a>
                        <a className='nug' data-bs-toggle="collapse" href="#collapse" role="button" aria-expanded="false" aria-controls="collapse">
                            How to identify?
                        </a>
                    </div>
                    <div className="collapse" id="collapse">
                        <div className='nugtop'>
                            <Row>
                                <Col md={6}>
                                    <Col>
                                        <img src={constants.office} className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <strong className='subbannertitle'> NUG Official website links usually end with .nugmyanmar.org</strong>
                                        <p className='nugctext'>National Unity Government communicate via <strong>.nugmyanmar.org</strong> websites. (e.g. go.nugmyanmar.org/open) <a href="https://trustedwebsites.nugmyanmar.org" className="banner-content-text bp-masthead-button-link bp-masthead-button-text link" target="_blank" rel="noreferrer">Trusted websites <FaExternalLinkAlt /></a></p>
                                    </Col>
                                </Col>
                                <Col md={6}>
                                    <Col>
                                        <img src={constants.lock} className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <strong className='subbannertitle'> Secure websites use HTTPS</strong>
                                        <p className='nugctext'>Look for a <strong> lock </strong>(<img src={constants.lock} className="inline-banner-icon innericon"></img>) or https:// as an added precaution. Share sensitive information only on official, secure websites.</p>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </Container>
        );
    }
}