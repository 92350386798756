// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, orderBy, setDoc, deleteDoc, doc, getDoc, getDocs, query, where, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { redirect } from "react-router-dom";

const SECRET_KEY = 'XX2sJnT9(Q)<Z!TP19lP4Dc4@*>>QZ';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDvlCrhdQnBB69vQmEHbPWHs-LwxzgiHu4",
    authDomain: "datanugmyanmar.firebaseapp.com",
    projectId: "datanugmyanmar",
    storageBucket: "datanugmyanmar.appspot.com",
    messagingSenderId: "443379890517",
    appId: "1:443379890517:web:e942936db975a69e9bd7dc",
    measurementId: "G-8PRNZGY2VC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

const colRef = collection(db, "data");

const accountRegistration = async (name, govagency, verify, individual, isadmin, createduseremail, createduserpassword, originalTextofCurrentUserEmail) => {
    console.log(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword);
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, createduseremail, createduserpassword);
        setDoc(doc(db, "users", createduseremail), {
            "name": name,
            "email": createduseremail,
            "govagency": govagency,
            // "verify": verify,
            // "individual": individual,
            "role": isadmin,
            "approvedby": originalTextofCurrentUserEmail,
            "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
            "serverTimestamp": new Date()
        });
        await updateProfile(userCredential.user, { displayName: name });
        sendEmailVerification(userCredential.user);
        return { code: 200, message: "Successfully Submitted!" };
    } catch (err) {
        console.error(err);
        throw err;
    }
};

const sendEmailVerificationAgain = () => {
    return sendEmailVerification(auth.currentUser)
        .then(() => ({ code: 200, message: "Verification email sent!" }))
        .catch((error) => {
            console.error('Failed to send verification email: ', error);
            return { code: 400, message: "Failed to send verification email" };
        });
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;

        if (user) {
            console.log('User authenticated:', user.email);
        } else {
            console.error('User authentication failed.');
            alert('User authentication failed.');
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};

const logout = (event) => {
    event.preventDefault(); // Cancel the default browser behavior

    // Perform logout here
    auth.signOut()
        .then(() => {
            // Logout successful
            console.log('User logged out.');
            localStorage.removeItem('ue');
            localStorage.clear();
            window.localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            // Handle logout error
            console.error('Error logging out:', error);
        });
};

const getCurrentUserDetails = async () => {
    try {
        const user = auth.currentUser;
        if (user) {
            console.log('User authenticated:', user.email);
            const userDoc = await getDoc(doc(db, "users", user.email));
            if (userDoc.exists()) {
                return userDoc.data();
            } else {
                console.error('User not found in database');
                return null;
            }
        } else {
            console.error('User not authenticated');
            return null;
        }
    } catch (err) {
        console.error(err.message);
        return null;
    }
}

const insertData = async (dataObj) => {
    try {
        console.log(dataObj);
        await addDoc(collection(db, "data"), dataObj);
        return { code: 200, message: "Successfully Submitted!" };
    } catch (err) {
        console.error(err.message);
        return { code: 400, message: err.message };
    }
}

const getCurrentUserGovAgency = async (currentUserEmail) => {
    try {
        const userDoc = await getDoc(doc(db, "users", currentUserEmail));
        if (userDoc.exists()) {
            return userDoc.data().govagency;
        } else {
            return null;
        }
    } catch (err) {
        console.error(err.message);
        return null;
    }
}

const generateShortLink = async (linkTitle, originalLink, customLink) => {
    try {
        var idcode = '';
        const uuid = 'xxxxxxxxxx'.replace(/x/g, () => Math.floor(Math.random() * 16).toString(16));
        var duuid = '';

        if (customLink !== '') {
            duuid = customLink;
            const docSnapshot = await getDoc(doc(db, 'goto', duuid));
            if (docSnapshot.exists()) {
                console.log('Document data:', docSnapshot.data());
                return { code: 400, message: "This custom link is already exist!", customid: customLink };
            } else {
                let currentTimeStamp = new Date();
                await setDoc(doc(colRef, duuid), {
                    "name": linkTitle,
                    "addedby": auth.currentUser.email,
                    "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
                    "serverTimestamp": new Date(),
                    "url": originalLink,
                    "active": true,
                    "clicks": 0,
                    "isprotected": false,
                    "password": "",
                    "activityLog": [
                        {
                            [currentTimeStamp]: {
                                "useremail": auth.currentUser.email,
                                "timestamp": new Date(),
                                "activity": "Link Created"
                            }
                        }
                    ]
                });
                // await setDoc(doc(colforStatRef, duuid), {
                //     "statistics": [
                //         {
                //             [currentTimeStamp]: {
                //                 "useremail": auth.currentUser.email,
                //                 "timestamp": new Date(),
                //                 "activity": "Created"
                //             }
                //         }
                //     ]
                // });
                idcode = duuid;
                var templateParams = {
                    linkTitle: linkTitle,
                    RECIPIENT_EMAIL: auth.currentUser.email,
                    originalLink: originalLink,
                    idcode: idcode
                };
                // await emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
                //     console.log('SUCCESS!', response.status, response.text);
                // }, function (error) {
                //     console.log('FAILED...', error);
                // });
                return { code: 200, message: "Successfully Submitted!", customid: idcode };
            }
        } else {
            let currentTimeStamp = new Date();
            duuid = uuid.substring(0, 8);
            await setDoc(doc(colRef, duuid), {
                "name": linkTitle,
                "addedby": auth.currentUser.email,
                "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
                "serverTimestamp": new Date(),
                "url": originalLink,
                "active": true,
                "clicks": 0,
                "isprotected": false,
                "password": "",
                "activityLog": [
                    {
                        [currentTimeStamp]: {
                            "useremail": auth.currentUser.email,
                            "timestamp": new Date(),
                            "activity": "Link Created"
                        }
                    }
                ]
            });
            // await setDoc(doc(colforStatRef, duuid), {
            //     [currentTimeStamp]: {
            //         "useremail": auth.currentUser.email,
            //         "timestamp": new Date(),
            //         "activity": "Created"
            //     }
            // });
            idcode = duuid;
            var templateParams = {
                linkTitle: linkTitle,
                RECIPIENT_EMAIL: auth.currentUser.email,
                originalLink: originalLink,
                idcode: idcode
            };
            // await emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
            //     console.log('SUCCESS!', response.status, response.text);
            // }, function (error) {
            //     console.log('FAILED...', error);
            // });
            return { code: 200, message: "Successfully Submitted!", customid: idcode };
        }
    } catch (err) {
        console.error(err.message);
        return { code: 400, message: err.message };
    }
};

const getCurrentUserData = async () => {
    if (auth.currentUser) {
        console.log('User authenticated:', auth.currentUser.email);
        try {
            const querySnapshot = await getDocs(colRef.where("user", "==", auth.currentUser.email));
            const dataList = querySnapshot.docs.map((doc) => {
                return {
                    id: doc.id, // This line gets the document ID
                    ...doc.data(), // This line gets the document data
                };
            });
            return dataList;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    } else {
        console.error('User not authenticated');
    }
}

const getData = async () => {
    try {
        const querySnapshot = await getDocs(colRef);
        const dataList = querySnapshot.docs.map((doc) => {
            return {
                id: doc.id, // This line gets the document ID
                ...doc.data(), // This line gets the document data
            };
        });
        return dataList;
    } catch (err) {
        console.error(err.message);
        return [];
    }
};

const getShortenLinksData = async (originalTextofEmail) => {
    try {
        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const shortenlinksDb = collection(db, "goto");
                    // const currentUserEmail = user.email.toString();
                    const currentUserEmail = originalTextofEmail;
                    const queryData = query(shortenlinksDb, where("addedby", "==", currentUserEmail), orderBy("timestamp"));
                    const getData = await getDocs(queryData);
                    const dataList = getData.docs.map((doc) => {
                        return {
                            id: doc.id, // This line gets the document ID
                            ...doc.data(), // This line gets the document data
                        };
                    });
                    unsubscribe(); // Unsubscribe to onAuthStateChanged once we have the data
                    resolve(dataList);
                } else {
                    console.error('User not authenticated');
                    unsubscribe(); // Unsubscribe to onAuthStateChanged if user is not authenticated
                    resolve([]); // Resolve with an empty array
                }
            }, (error) => {
                console.error('Error in onAuthStateChanged:', error);
                reject(error);
            });
        });
    } catch (err) {
        console.error(err.message);
        return [];
    }
};

const getUserListData = async (currentUserIsAdmin) => {
    try {
        const usersDb = collection(db, "users");
        const queryData = query(usersDb, orderBy("timestamp"));
        const getData = await getDocs(queryData);
        const dataList = getData.docs.map((doc) => {
            return {
                id: doc.id, // This line gets the document ID
                ...doc.data(), // This line gets the document data
            };
        });
        return dataList;
    } catch (err) {
        console.error(err.message);
        return [];
    }
};

const deleteShortLink = async (shortenLink, originalLink, userEmail) => {

    var rescode = '';
    var resmessage = '';

    const documentSnapshot = await getDoc(doc(db, 'goto', shortenLink.substring(26)));
    if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        const dataValue = data && data.url;
        console.log(dataValue);

        if (data.url === originalLink && data.addedby === userEmail) {
            console.log(shortenLink);
            await deleteDoc(doc(db, "goto", shortenLink.substring(26))).then(() => {
                console.log('Document successfully deleted!');
                rescode = '200';
                resmessage = "Successfully Deleted!";
            }).catch((error) => {
                console.error('Error deleting document:', error);
                rescode = '400';
                resmessage = "An error occur! caused by " + error.message;
            });
        } else if (data.url !== originalLink && data.addedby === userEmail) {
            rescode = '400';
            resmessage = "Original link is missing!"
        } else {
            rescode = '400';
            resmessage = "You don't have permission to delete this url!";
        }

    } else {
        rescode = '400';
        resmessage = "We didn't find any URL like this!";
    }

    return { code: rescode, message: resmessage }

}

const removeShortenLinksData = async (shortenLinkId) => {
    await deleteDoc(doc(db, "goto", shortenLinkId)).then(() => {
        console.log("Successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

export {
    auth,
    db,
    signInWithEmailAndPassword,
    logInWithEmailAndPassword,
    accountRegistration,
    logout,
    generateShortLink,
    deleteShortLink,
    getShortenLinksData,
    removeShortenLinksData,
    SECRET_KEY,
    firebaseConfig,
    getUserListData,
    sendEmailVerificationAgain,
    getCurrentUserGovAgency,
    getData,
    getCurrentUserDetails,
    insertData
};