import logo from "../../src/assets/image/logo.svg";

export const constants = {
    appversion: "1.0.20240102/V1Pre",
    appname: "Edu.NUG",
    appnamefull: "edu.nugmyanmar.org",
    SECRET_KEY: 'XX2sJnT9(Q)<Z!TP19lP4Dc4@*>>QZ',
    lock: "https://cdn.egov.nugmyanmar.org/assets/lock.svg",
    greenlock: "https://cdn.egov.nugmyanmar.org/assets/greenlockicn.svg",
    office: "https://cdn.egov.nugmyanmar.org/assets/government_building.svg",
    stateemblem: "https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg",
    // logo: "https://cdn.egov.nugmyanmar.org/assets/datanug/Data.NUG%20LOGO.svg"
    logo: logo,
};