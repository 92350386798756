import React, { useEffect, useState } from "react";
import { Container, Form, Button, Modal, Nav } from 'react-bootstrap';
import { accountRegistration, getCurrentUserDetails, insertData } from "../../firebase.js";
import {
    auth,
    // logout
} from "../../firebase";
import { constants } from "../../global/const";
import CryptoJS from 'crypto-js';
// import "./Accreg.css";
import "../Home/home.css";
import LoadingSceen from "../Loading/Loading.js";
import Topbar from "../Topbar/Topbar";
import Navbar from '../Header/Header';
import './CreateData.css';

const checkValidation = (title, data, unit, desc, govAgency, date, useremail) => {
    if (title == "" || data == "" || unit == "" || govAgency == "") {
        alert("Please fill in all fields.");
    } else {
        const dataObj = {
            title: title,
            data: data,
            dataunit: unit,
            desc: desc ? desc : "null",
            org: govAgency,
            featured: false,
            user: useremail,
            date: new Date(date).toLocaleDateString('en-GB')
        }
        insertData(dataObj).then((result) => {
            console.log(result);
            if (result.code == 200) {
                alert("Data created successfully.");
                window.location.href = "/home.adm";
            } else {
                alert("Data creation failed. Please try again.");
            }
        });
        console.log(dataObj);
    }
}

function CreateData() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [useremail, setUseremail] = useState("");
    const [userrole, setUserrole] = useState("");
    // const [org, setOrg] = useState("");

    const [title, setTitle] = useState("");
    const [data, setData] = useState("");
    const [unit, setUnit] = useState("");
    const [desc, setDesc] = useState("");
    const [date, setDate] = useState("");
    const [govAgency, setGovAgency] = useState("");
    const [isPST, setIsPST] = useState(false);

    useEffect(() => {
        setLoading(true);
        auth.onAuthStateChanged((user) => {
            if (!user) {
                setLoading(false);
                window.location.href = "/";
            } else {
                setUsername(user.displayName);
                setUseremail(user.email);
                getCurrentUserDetails().then((result) => {
                    setUserrole(result.role);
                    setGovAgency(result.govagency);
                    if (result.govagency == "PST") {
                        setIsPST(true);
                    }
                    setLoading(false);
                });
            }
        });
    }, []);

    return (
        loading ?
            <LoadingSceen />
            :
            userrole !== "admin" ?
                <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div style={{ textAlign: "center" }}>
                        <h1>403 Forbidden</h1>
                        <p>You are not authorized to access this page.</p>
                        <p>Please contact the administrator for more information.</p>
                        <Nav.Link href="/home.adm"><u>Go back to home</u></Nav.Link>
                    </div>
                </Container>
                :
                <>
                    <Topbar />
                    <Navbar name={username} />
                    <Container fluid className="d-flex justify-content-center">
                        <div className="dataCreateForm">
                            <h1>Create Data</h1>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter title"
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter data"
                                        onChange={(e) => setData(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Unit</Form.Label>
                                    <Form.Control as="select"
                                        onChange={(e) => setUnit(e.target.value)}>
                                        <option value="">Select unit</option>
                                        <option value="llun">Null</option>
                                        <option value="+">+</option>
                                        <option value="K">K</option>
                                        <option value="K+">K+</option>
                                        <option value="M">M</option>
                                        <option value="M+">M+</option>
                                        <option value="B">B</option>
                                        <option value="B+">B+</option>
                                        <option value="T">T</option>
                                        <option value="T+">T+</option>
                                        <option value="%">%</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter description"
                                        onChange={(e) => setDesc(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Enter date"
                                        onChange={(e) => setDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Government Agency</Form.Label>
                                    {/* <Form.Control
                                        defaultValue={govAgency}
                                        disabled={isPST ? false : true}
                                        onChange={(e) => setGovAgency(e.target.value)}
                                        type="text"
                                    /> */}
                                    <Form.Select
                                        required
                                        defaultValue={govAgency}
                                        disabled={isPST ? false : true}
                                        onChange={e => setGovAgency(e.target.value)}>
                                        <option value="MoD">Ministry of Defence</option>
                                        <option value="MoH">Ministry of Health</option>
                                        <option value="MoPFI">Ministry of Planning, Finance and Investment</option>
                                        <option value="MoFA">Ministry of Foreign Affairs</option>
                                        <option value="MoE">Ministry of Education</option>
                                        <option value="MoHAI">Ministry of Home Affairs and Immigration</option>
                                        <option value="MoFUA">Ministry of Federal Union Affairs</option>
                                        <option value="MoHADM">Ministry of Humanitarian Affairs and Disaster Management</option>
                                        <option value="MoHR">Ministry of Human Rights</option>
                                        <option value="MoNREC">Ministry of Natural Resources and Environmental Conservation</option>
                                        <option value="MoIC">Ministry of International Cooperation</option>
                                        <option value="MoWYCA">Ministry of Women, Youths and Children Affairs</option>
                                        <option value="MoL">Ministry of Labour</option>
                                        <option value="MoJ">Ministry of Justice</option>
                                        <option value="MoCIT">Ministry of Communications, Information & Technology</option>
                                        <option value="MoEE">Ministry of Electricity & Energy</option>
                                        <option value="MoC">Ministry of Commerce</option>
                                        <option value="PST">PST</option>
                                        <option value="cdmdata">CDM Data</option>
                                        <option value="dataformyanmar">Data for Myanmar</option>
                                        <option value="idpbnireport">IDPs BNI report</option>
                                        <option value="AAPP">AAPP</option>
                                        <option value="thde">The Humanitarian Data Exchange</option>
                                        <option value="OHCHER">OHCHER</option>
                                        <option value="UNHCR">UNHCR</option>
                                    </Form.Select>
                                    <Form.Text className="text-muted">
                                        {govAgency != "PST" ? "You are not allowed to change this field." :
                                            "MoCIT, MoD, MoH, MoE, MoLIP, MoNREC or PST case sensitive."
                                        }

                                    </Form.Text>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => checkValidation(title, data, unit, desc, govAgency, date, useremail)}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </Container>
                    {/* <p>{username} {useremail} {userrole}</p> */}
                </>
    )
}

export default CreateData;