import React, { useEffect, useState } from "react";
import { Container, Form, Button, Col, Row, Spinner, Modal, Table, Nav, NavDropdown } from 'react-bootstrap';
import { auth, logInWithEmailAndPassword, firebaseConfig, getData } from "../../firebase";
import { updateDoc, doc, getDocs, collection, query, where, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { GoDotFill, GoFoldDown } from "react-icons/go";
import { BiTrash, BiCopy, BiExpand } from "react-icons/bi";
import { MdOutlineQueryStats } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import Loading from "../Loading/Loading";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { initializeApp } from "firebase/app";
import { getFirestore, arrayUnion } from "firebase/firestore";
import 'react-toastify/dist/ReactToastify.css';
// import * as QRCode from 'qrcode';
import { MdQrCodeScanner } from "react-icons/md";
// import { createCanvas } from 'canvas';
import './view.css';
import Topbar from "../Topbar/Topbar";
import Navbar from '../Header/Header';
import { set } from "firebase/database";


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function formatDate(datenew) {
    const date = new Date(datenew);
    const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
    return formattedDate;
}

function logoutThis() {
    auth.signOut().then(() => {
        window.location = '/';
    }).catch((error) => {
        console.error('Signout Error:', error);
    });
}

const saveDataChange = (title, data, unit, desc, date, id) => {
    console.log(title, data, unit);
    const db = getFirestore();
    updateDoc(doc(db, "data", id), {
        title: title,
        data: data,
        dataunit: unit,
        desc: desc ? desc : "null",
        date: date
    }).then(() => {
        console.log("Document successfully updated!");
        window.location.reload();
    }).catch((error) => {
        console.error("Error updating document: ", error);
    });
};

function View() {
    const [shortenLists, addData] = useState([]);
    const [listLength, setListLength] = useState(0);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const [title, setTitle] = useState("");
    const [data, setData] = useState("");
    const [unit, setUnit] = useState("");
    const [desc, setDesc] = useState("");
    const [date, setDate] = useState("");
    const [id, setId] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setId(data.id);
        setTitle(data.title);
        setData(data.data);
        setUnit(data.dataunit);
        setSelectedData(data);
        setDesc(data.desc);
        setDate(data.date);
        setShow(true);
    };

    useEffect(() => {
        setLoading(true);
        getData()
            .then((dataList) => {
                console.log('Fetched data:', dataList)
                console.log('Data length:', dataList.length)// Add this line
                addData(dataList);
                setListLength(dataList.length);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            }

            );
    }, []);

    return (
        <>
            <Topbar />
            <Navbar name={null} />
            <body className="bg-white bodydiv">
                {loading ?
                    < Container className="py-3">
                        <Spinner
                            animation="grow"
                            variant="dark"
                        />
                    </Container>
                    :
                    <>
                        <Container className="py-3">
                            <h4 className="py-3">Total Data: {listLength}</h4>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th md={6}>Title <span className=''><GoFoldDown /></span></th>
                                        <th md={2} className='textaligncen'>Data Count</th>
                                        <th md={2} className='textaligncen'>Data Unit</th>
                                        <th md={2} className='textaligncen'>Latested Updated Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="tabledata">
                                    {shortenLists.data === null ? (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    ) : (
                                        [...shortenLists]
                                            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                            .map((data, index) => (
                                                <tr key={index} className="py-4">
                                                    <td>
                                                        <label>{index + 1}.</label>
                                                    </td>
                                                    <td md={6}>
                                                        <label className='bold-text'>{data.title}</label>
                                                    </td>
                                                    <td md={2} className='textaligncen'>
                                                        <label htmlFor="">{data.data}</label>
                                                    </td>
                                                    <td md={2} className='textaligncen'>
                                                        <label htmlFor="">{
                                                            data.dataunit === "llun" ? "Null" : data.dataunit
                                                        }</label>
                                                    </td>
                                                    <td md={2} className='align-center textaligncen'>
                                                        <label>
                                                            {data.date}
                                                        </label>
                                                    </td>
                                                    <td md={2} className='align-center textaligncen'>
                                                        <Button
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            onClick={() => handleShow(data)}
                                                        >
                                                            Edit Data
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )))}
                                </tbody>
                            </Table>
                        </Container>
                        <p className="text-muted sm" style={{ textAlign: "center", fontSize: "10px" }}>Powered by SmartGov, MoCIT</p>
                    </>

                }
            </body >
            <Modal show={show} data onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit on {selectedData ? selectedData.title : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                defaultValue={selectedData ? selectedData.title : ''}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Data</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter data"
                                autoFocus
                                defaultValue={selectedData ? selectedData.data : ''}
                                onChange={(e) => setData(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Unit</Form.Label>
                            <Form.Control as="select"
                                defaultValue={selectedData ? selectedData.dataunit : ''}
                                onChange={(e) => setUnit(e.target.value)}>
                                <option value="">Select unit</option>
                                <option value="llun">Null</option>
                                <option value="+">+</option>
                                <option value="K">K</option>
                                <option value="K+">K+</option>
                                <option value="M">M</option>
                                <option value="M+">M+</option>
                                <option value="B">B</option>
                                <option value="B+">B+</option>
                                <option value="T">T</option>
                                <option value="T+">T+</option>
                                <option value="%">%</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={selectedData ? selectedData.desc : ''}
                                placeholder="Enter description"
                                onChange={(e) => setDesc(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                disabled
                                defaultValue={selectedData ? formatDate(selectedData.date) : ''}
                                placeholder="Enter date"
                                onChange={(e) => setDate(e.target.value)} />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Example textarea</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => saveDataChange(title, data, unit, desc, date, id)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );

}
export default View;