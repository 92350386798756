import React, { useState, useEffect } from "react";
import { Container, Form, Button, Modal, Navbar, Nav } from 'react-bootstrap';
import { accountRegistration, auth, SECRET_KEY } from "../../firebase.js";
import { logout } from "../../firebase";
import { constants } from "../../global/const";
// import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import "./Accreg.css";
import "../Home/home.css";
// import "./../../Global/shared-styles.css"

function Accreg() {
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [name, setName] = useState("");
  const [govagency, setGovagency] = useState("");
  const [verify, setVerify] = useState(false);
  const [individual, setIndividual] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [createduseremail, setCreatedUserEmail] = useState("");
  const [createduserpassword, setCreatedUsersPassword] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const currentUserIsAdmin = localStorage.getItem('iam');
  const dataciphertext = JSON.parse(localStorage.getItem('ue'));
  const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
  const originalTextofCurrentUserEmail = bytes.toString(CryptoJS.enc.Utf8);

  const handleSignup = async () => {
    try {
      await accountRegistration(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword, originalTextofCurrentUserEmail)
        .then((result) => {
          setModalMessage(result.message);
          setShowModal(true);
        });
    } catch (err) {
      setModalMessage(err.message);
      setShowModal(true);
    }
  };

  useEffect(() => {
    // setLoading(true);
    auth.onAuthStateChanged((user) => {
      if (!user) {
        // setLoading(false);
        window.location.href = "/";
      } else {
        setUsername(user.displayName);
        setUseremail(user.email);
      }
    });
  }, []);

  return (
    useremail !== 'dave@mocitmyanmar.org' ? (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div style={{ textAlign: "center" }}>
          <h1>403 Forbidden</h1>
          <p>You are not authorized to access this page.</p>
          <p>Please contact the administrator for more information.</p>
          <Nav.Link href="/home.adm"><u>Go back to home</u></Nav.Link>
        </div>
      </Container>
    )
      :
      <>
        <Navbar bg="light" data-bs-theme="light" sticky="top" className="navbar">
          <Container>
            <Navbar.Brand href="#home">
              <img
                src={constants.logo}
                height="40"
                className="d-inline-block align-top"
                alt="Data.NUG Logo"
              />
              {/* <span className='slug text-muted'>/</span>
            <span className="slug text-muted">User Create</span> */}
            </Navbar.Brand>
            <Nav
              inline
              className="me-auto">
              <Nav.Link href="#userlists">User Lists</Nav.Link>
            </Nav>
            <Button
              inline
              variant="secondary"
              onClick={(event) => {
                event.preventDefault();
                window.location = '/home.adm';
              }}
            >
              Back to Home
            </Button>
          </Container>
        </Navbar>
        {/* <div className="d-flex justify-content-between logoutbtn">

        <div>
          <button
            className="btn btn-outline-secondary m-3"
            onClick={(event) => {
              event.preventDefault();
              window.location = '/home.adm';
            }}
          >
            Back to Home
          </button>
        </div>

        <div className='navbar'>
          <Navbar.Brand href="/home.adm">
            <img
              src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
              width="40"
              height="40"
              className="d-inline-block align-center"
              alt="Go.NUG Logo"
            />
          </Navbar.Brand>
          <span className='slug text-muted'>/</span>
          <span className="slug text-muted">User Create</span>
          <button className="btn btn-outline-secondary customBtnBg" onClick={() => { window.location.href = '/go.userlists.adm'; }}>User Lists</button>
        </div>
      </div> */}

        <Container className='acccreatecontainer align-items-center d-flex navless-container'>

          <div className="m-auto formwidth">
            {/* <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" /> */}

            <Form.Group className="my-3" controlId="formBasicName">
              <Form.Label>Name *</Form.Label>
              <Form.Control required type="text" onChange={e => setName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gov Agency *</Form.Label>
              <Form.Select required onChange={e => setGovagency(e.target.value)}>
                <option value="MoD">Ministry of Defence</option>
                <option value="MoH">Ministry of Health</option>
                <option value="MoPFI">Ministry of Planning, Finance and Investment</option>
                <option value="MoFA">Ministry of Foreign Affairs</option>
                <option value="MoE">Ministry of Education</option>
                <option value="MoHAI">Ministry of Home Affairs and Immigration</option>
                <option value="MoFUA">Ministry of Federal Union Affairs</option>
                <option value="MoHADM">Ministry of Humanitarian Affairs and Disaster Management</option>
                <option value="MoHR">Ministry of Human Rights</option>
                <option value="MoNREC">Ministry of Natural Resources and Environmental Conservation</option>
                <option value="MoIC">Ministry of International Cooperation</option>
                <option value="MoWYCA">Ministry of Women, Youths and Children Affairs</option>
                <option value="MoL">Ministry of Labour</option>
                <option value="MoJ">Ministry of Justice</option>
                <option value="MoCIT">Ministry of Communications, Information & Technology</option>
                <option value="MoEE">Ministry of Electricity & Energy</option>
                <option value="MoC">Ministry of Commerce</option>
                <option value="PST">PST</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 d-flex justify-content-between">

              <Form.Check // prettier-ignore
                type="switch"
                id="isadmin-switch"
                label="Is Admin"
                checked={isadmin}
                onChange={e => setIsadmin(e.target.checked ? "admin" : "user")}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>Email address *</Form.Label>
              <Form.Control required type="email" onChange={e => setCreatedUserEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label>Password *</Form.Label>
              <Form.Control required type="password" onChange={e => setCreatedUsersPassword(e.target.value)} />
            </Form.Group>

            <div className="d-grid gap-2">
              {/* <Button variant="danger" type="submit" className='loginBtn' onClick={() => logInWithEmailAndPassword(email, password)}>
              Sign in
            </Button> */}
              <Button variant="dark" type="submit" className='loginBtn' onClick={() => handleSignup(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword)}>
                Register Account
              </Button>
            </div>

            {/* <div className="my-4">
            <TurnstileWidget setIsTurnstileCompleted={setIsTurnstileCompleted} />
          </div> */}

          </div>

          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Info</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>{modalMessage}</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        </Container>
      </>
  );


}
export default Accreg;