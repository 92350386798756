import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Badge,
    Button,
    // Alert,
    Card
} from 'react-bootstrap';
import '../../App.css';
import './home.css';
import Topbar from '../Topbar/Topbar';
import Navbar from '../Header/Header';
import CountUp from 'react-countup';
import LoadingScreen from "../Loading/Loading";
import TitleHeader from "../Title/Title";
import DataCard from "../DataCard/datacard";
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc } from 'firebase/firestore';
import {
    auth,
    getCurrentUserDetails,
    getData
    // logout
} from "../../firebase";
import { constants } from "../../global/const";
import { useNavigate } from 'react-router-dom';
import { FaDownload } from "react-icons/fa";
import { isMobile } from 'react-device-detect';

// const data = [];

// useEffect(() => {
//     auth.onAuthStateChanged((user) => {
//         if (!user) {
//             window.location.href = "/";
//         } else {

//             getData().then((result) => {
//                 result.forEach((doc) => {
//                     data.push(doc.data());
//                 });
//             });

//         }
//     });
// }, []);


let app;
if (!getApps().length) {
    app = initializeApp({
        // your firebase config
    });
} else {
    app = getApp();
}
const db = getFirestore(app);


function Home() {

    const [data, setData] = useState([]);
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [printView, setPrintView] = useState(false);
    const [orgName, setOrgName] = useState('');
    const [viewDetailPrintOrgName, setViewDetailPrintOrgName] = useState('');

    const [useremail, setUseremail] = useState('');
    const [userrole, setUserrole] = useState('');
    const [govAgency, setGovAgency] = useState('');

    const [viewDetail, setViewDetail] = useState(false);

    const navigate = useNavigate();

    const printViewHandler = () => {
        window.scrollTo(0, 0);
        setPrintView(prevPrintView => !prevPrintView);
    };



    const viewDetailsHandler = (org) => () => {
        if (org === "null") {
            setOrgName('');
            setViewDetail(false);
        } else {
            setData(data);
            setOrgName(org);
            setViewDetail(prevViewDetail => !prevViewDetail);
        }
        // if (org === "all") {
        //     navigate("/detail", { state: { dataArr: data, orgName: "All" } });

        // } else {
        //     const filteredData = data.filter(item => item.org.toLowerCase() === org);
        //     navigate("/detail", { state: { dataArr: filteredData, orgName: org } });
        //     // window.location.href = "/detail";
        // }
    };

    const printHandler = () => {
        /**
         * @todo ဒီနေရာမှာ Print Log ထည့်ပြီး ပြန်ရလာတဲ့ uuid ကို document title အနောက်မှာထည့်သွင်းရန်
        **/
        document.title = "Data.NUG Report";
        document.body.style.cssText = "margin: 0 !important";
        // Create a new div for the footer
        const footer = document.createElement("div");
        footer.style.position = "fixed";
        footer.style.bottom = "0";
        footer.style.width = "100%";
        footer.style.textAlign = "center";
        footer.style.fontSize = "0.6rem";
        footer.textContent = "Report Generated from Data.NUG by " + username + " on " + new Date().toLocaleString('en-GB', { timeZone: 'Asia/Yangon' }) + " (MMT)";
        // Append the footer to the body
        document.body.appendChild(footer);

        window.print();

        // Remove the footer after printing
        document.body.removeChild(footer);
    }

    useEffect(() => {
        setLoading(true);
        auth.onAuthStateChanged((user) => {
            if (!user) {
                setLoading(false);
                window.location.href = "/";
            } else {

                setUsername(user.displayName);
                setUseremail(user.email);

                const getData = async () => {
                    const snapshot = await getDocs(collection(db, 'data'));
                    return snapshot.docs;
                };
                getData().then((result) => {
                    const newData = [];
                    result.forEach((doc) => {
                        newData.push(doc.data());
                    });
                    setData(newData);
                    setLoading(false);
                });

                // getCurrentUserDetails().then((result) => {
                //     setUserrole(result.role);
                //     setGovAgency(result.govagency);
                // });
            }
        });
    }, []);

    return (
        <div className="App">

            {viewDetail ? (
                <>
                    <Topbar />
                    <Navbar name={username} />
                    <TitleHeader
                        title={orgName}
                        desc={"/home.adm/" + orgName}
                    />

                    <div className="bodyContainer">
                        <Container className="mb-4">
                            <div className="d-flex" style={{ textAlign: "end" }}>
                                <Col
                                    style={{ textAlign: "end" }}>
                                    <Button
                                        variant="secondary"
                                        onClick={viewDetailsHandler("null")}>
                                        Back to Home
                                    </Button>
                                </Col>
                            </div>
                            <Row sm={12}>
                                {orgName === "all" ?
                                    data
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })
                                    :
                                    data
                                        .filter(item => item.org.toLowerCase() === orgName.toLowerCase())
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })
                                }
                            </Row>
                        </Container>
                    </div>
                </>
            ) : loading ? (
                <>
                    <LoadingScreen />
                </>
            ) : printView ? (
                <>
                    <div className="printHeader my-3">
                        <Container fluid>
                            <Row>
                                <Col
                                    style={{ textAlign: "start" }}
                                    className="d-flex">
                                    <img
                                        src={constants.logo}
                                        height="40"
                                        className="d-inline-block align-top"
                                        alt="Data.NUG Logo"
                                    />
                                </Col>
                                <Col
                                    style={{ textAlign: "end" }}>
                                    <Button
                                        onClick={printHandler}
                                        variant="secondary"
                                        className="me-2">
                                        Print
                                    </Button>
                                    <Button
                                        onClick={printViewHandler}
                                        variant="secondary" >
                                        Exit Print View
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <Container fluid className="mb-4">
                            {/* <div className="my-3">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            Latest Updated Data
                                        </h4>
                                    </Col>
                                </Row>
                                <Row sm={12}>
                                    {data
                                        // .filter(item => item.org.toLowerCase() === 'mocit')
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        // .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div> */}

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoH
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'moh')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoE
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'moe')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoD
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mod')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoHADM
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mohadm')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoHR
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mohr')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoEE
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'moee')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoHAI
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mohai')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoWYCA
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mowyca')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoNREC
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'monrec')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoJ
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'moj')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoL
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mol')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoFA
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mofa')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            MoPFI
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'mopfi')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            CDM Data
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'cdmdata')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            Data for Myanmar
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'dataformyanmar')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            IDPs BNI Report
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'idpbnireport')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            AAPP
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'aapp')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            The Humanitarian Data Exchange
                                        </h4>
                                    </Col>
                                    <Col
                                        style={{ textAlign: "end" }}>
                                        <Button
                                            size="sm"
                                            onClick={viewDetailsHandler("thde", data)}
                                            variant="secondary" >
                                            View All
                                        </Button>
                                    </Col>
                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'thde')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            OHCHER
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'ohcher')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>

                            <div className="my-4">
                                <Row>
                                    <Col className="d-flex">
                                        <h4 style={{ color: "gray" }}>
                                            UNHCR
                                        </h4>
                                    </Col>

                                </Row>
                                <Row sm={12}>
                                    {data
                                        .filter(item => item.org.toLowerCase() === 'unhcr')
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <DataCard
                                                    key={index}
                                                    title={item.title}
                                                    data={item.data}
                                                    dataunit={item.dataunit}
                                                    desc={item.desc}
                                                    date={item.date}
                                                    org={item.org}
                                                />
                                            );
                                        })}
                                </Row>
                            </div>
                        </Container>
                    </div>
                </>
            )
                : (
                    <>
                        <Topbar />
                        <Navbar name={username} />
                        <TitleHeader
                            title={"Hello, " + username}
                            desc="Welcome from the Data.NUG. We are glad to have you here."
                        />

                        <div className="bodyContainer">
                            <Container className="mb-4">
                                {isMobile ? null
                                    :
                                    <div className="d-flex" style={{ textAlign: "end" }}>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                variant="secondary"
                                                onClick={printViewHandler}>
                                                <FaDownload className="me-2" />
                                                Export PDF
                                            </Button>
                                        </Col>
                                    </div>
                                }

                                <div className="my-3">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray", textAlign: "left" }}>
                                                Latest Updated Data
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("all", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            // .filter(item => item.org.toLowerCase() === 'mocit')
                                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoH
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("moh", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'moh')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoE
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("moe", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'moe')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoD
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mod", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mod')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoHADM
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mohadm", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mohadm')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoHR
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mohr", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mohr')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoEE
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("moee", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'moee')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoHAI
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mohai", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mohai')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoWYCA
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mowyca", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mowyca')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoNREC
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("monrec", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'monrec')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoJ
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("moj", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'moj')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoL
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mol", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mol')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoFA
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mofa", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mofa')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                MoPFI
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("mopfi", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'mopfi')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                CDM Data
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("cdmdata", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'cdmdata')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                Data for Myanmar
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("dataformyanmar", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'dataformyanmar')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                IDPs BNI Report
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("idpbnireport", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'idpbnireport')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                AAPP
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("aapp", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'aapp')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                The Humanitarian Data Exchange
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("thde", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'thde')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                OHCHER
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("ohcher", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'ohcher')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>

                                <div className="my-4">
                                    <Row>
                                        <Col className="d-flex">
                                            <h4 style={{ color: "gray" }}>
                                                UNHCR
                                            </h4>
                                        </Col>
                                        <Col
                                            style={{ textAlign: "end" }}>
                                            <Button
                                                size="sm"
                                                onClick={viewDetailsHandler("unhcr", data)}
                                                variant="secondary" >
                                                View All
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row sm={12}>
                                        {data
                                            .filter(item => item.org.toLowerCase() === 'unhcr')
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <DataCard
                                                        key={index}
                                                        title={item.title}
                                                        data={item.data}
                                                        dataunit={item.dataunit}
                                                        desc={item.desc}
                                                        date={item.date}
                                                        org={item.org}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </>
                )
            }
        </div >
    );


}

export default Home;