import React, {
    // useState
} from "react";
import {
    Badge, Row, Col, Card
} from "react-bootstrap";
import CountUp from 'react-countup';
import "./datacard.css";

function DataCard(props) {
    console.log(props);
    return (
        <Col sm={3}>
            <Card>
                <Card.Body>
                    <Card.Title className="titleText">{props.title}</Card.Title>
                    <Card.Text style={{ fontSize: "2rem", fontWeight: "bold"}}>
                        <CountUp end={props.data} /> {props.dataunit != "llun" ? <span>{props.dataunit}</span> : null}
                    </Card.Text>
                    <Card.Text>
                        {props.desc === "null" ? null : props.desc}
                    </Card.Text>
                </Card.Body>
                <Card.Footer style={{ textAlign: "justify" }}>
                    <Row>
                        <Col style={{ textAlign: "start" }}>
                            <small className="text-muted">
                                {props.date}
                            </small>
                        </Col>
                        <Col style={{ textAlign: "end" }}>
                            <Badge bg="secondary" className="ms-2">{props.org}</Badge>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>
    );
}

export default DataCard;