import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { auth, logout, getCurrentUserDetails } from "../../firebase";
import { constants } from "../../global/const";
import "./Header.css";
import { isMobile } from 'react-device-detect';
import { get } from "firebase/database";

const Header = (props) => {

    const [isSticky, setSticky] = useState(false);
    // const [name, setName] = useState("");
    const [role, setRole] = useState("");

    const checkScrollTop = () => {
        setSticky(window.pageYOffset > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        getCurrentUserDetails().then((result) => {
            if (result) {
                setRole(result.role);
            }
        })
        return () => window.removeEventListener("scroll", checkScrollTop);
    }, []);

    return (
        <Navbar bg="light" data-bs-theme="light" sticky="top" className="navbar">
            <Container>
                <Navbar.Brand href="/home.adm">
                    <img
                        src={constants.logo}
                        height="40"
                        className="d-inline-block align-top"
                        alt="Data.NUG Logo"
                    />
                </Navbar.Brand>
                <Nav
                    inline
                    className="me-auto">
                    {props.name ? !isMobile && isSticky ? <Nav.Link disabled>Hello, {props.name}</Nav.Link> : null : null}
                    {role !== "admin" ?
                        null
                        :
                        <>
                            <Button
                                inline
                                type="submit"
                                variant="secondary"
                                size="sm"
                                onClick={() => window.location.href = "/createdata.adm"}
                                className="me-2"
                            >
                                Create
                            </Button>
                            <Button
                                inline
                                type="submit"
                                variant="secondary"
                                size="sm"
                                onClick={() => window.location.href = "/view.adm"}
                                className="me-2"
                            >
                                View
                            </Button>
                        </>
                    }

                </Nav>
                <Button
                    inline
                    type="submit"
                    size="sm"
                    variant="secondary"
                    onClick={logout}
                >
                    Logout
                </Button>
            </Container>
        </Navbar>
    );
}

export default Header;