import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataCard from "../DataCard/datacard";
import { useLocation } from "react-router-dom";
import { constants } from "../../global/const";
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import {
    auth,
    getData
    // logout
} from "../../firebase";
import "./viewDetail.css";
import LoadingScreen from "../Loading/Loading";

function ViewDetail() {

    const [data, setData] = useState([]);
    const [orgName, setOrgName] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    return (
        loading ?
            <LoadingScreen />
            : <Container>
                <Row>
                    <Col>
                        <h1>{orgName}</h1>
                        <p>home.adm/{orgName}</p>
                    </Col>
                </Row>
                <Row>
                    {data.map((data, index) => (
                        <DataCard
                            key={index}
                            title={data.title}
                            data={data.data}
                            dataunit={data.dataunit}
                            desc={data.desc}
                            date={data.date}
                            org={data.org}
                        />
                    ))}
                </Row>
            </Container>

    );
}

export default ViewDetail;