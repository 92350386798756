import React, { useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { constants } from "../../global/const";
import "./Title.css";


const TitleHeader = ({ title, desc }) => {

    return (
        <div className="titleHeader">
            <Container>
                <div style={{ textAlign: "left", padding: "6rem 0" }} className="titleDN">
                    <h1>{title}</h1>
                    <p>
                        {desc}
                    </p>
                </div>
            </Container>
        </div>

    );
}

export default TitleHeader;